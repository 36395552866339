@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,100&display=swap');

:root {
    --main-color: linear-gradient(0deg, #19494d, #19494d);
    --accent-color: #e8e7e8;
    --nav-text-color: whitesmoke;
    --text-color: black;
    --font-fam: 'Roboto', sans-serif;
}

html {
    overflow-x: hidden!important;
    font-family: var(--font-fam);
}

body {
    overflow-x: hidden!important;
}


/* Desktop Hero */

#hero {
    position: relative;
    height: 90vh;
}

#cName {
    position: absolute;
    margin-top: 50vh;
    font-size: 3.5rem;
    left: 10%;
    z-index: 10;
    color: var(--nav-text-color);
}

#deskVid {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 90vh;
}

#hero::before {
    position: absolute;
    z-index: 5;
    content: '';
    top: 0;
    left: 0;
    background-color: black;
    height: 100%;
    width: 100%;
    opacity: 0.4;
}

/* Hero Mobile */

#mobileVid {
    display: none;
}

/* Over Ons Desktop */

#overOns {
    margin-top: 5rem;
}

#intro {
    width: 100vw;
    display: flex;
    gap: 5rem;
}

#oText {
    width: 60%;
    text-align: center;
}

#oText h2 {
    margin-block: 4rem;
    font-size: 2.25rem;
}

#oText p {
    padding-inline: 8rem;
    font-size: 1.1rem;
}

#oFoto {
    width: 40%;
    height: calc(90% + 5rem);
}

#oFoto img {
    height: 100%;
    width: 100%;
}

#slogan {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

#slogan span {
    width: 90%;
    margin: auto;
    font-size: 2rem;
    font-weight: 900;
}

/* Over Ons Mobile */

@media screen and (max-width: 1200px) {
    #oText p {
        padding-inline: 2rem;
        text-align: center;
    }

    #oText {
        width: 100%;
    }

    #oFoto {
        display: none;
    }

    #slogan span {
        font-size: 1.25rem;
    }
}

/* Aanbod Desktop */

#aanbod {
    display: flex;
    gap: 5%;
    margin-top: 15rem;
    margin-bottom: 5rem;
    height: 60vh;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
}

#shape {
    position: absolute;
    bottom: 20%;
    left: -10%;
    z-index: -1;
    height: 40vh;
    min-height: 300px;
    width: calc(100% + 25rem);
    transform: rotate(-4deg);
    background: var(--main-color);
}

#aText {
    width: 35%;
    padding-left: 4rem;
}

#aText h2 {
    margin-bottom: 5rem;
    font-size: 3rem;
    font-weight: 400;
}

#aText p {
    margin-top: 5rem;
    color: var(--nav-text-color);
    font-size: 1.1rem;
    min-height: 300px;
}

#cards {
    display: flex;
    gap: 2rem;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 70%;
    min-height: 300px;
    width: 17rem;
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 4px 3px var(--accent-color);
}

.price {
    font-weight: 700;
    font-size: 2.2rem;
}

.unit {
    font-weight: 100;
    font-size: 1rem;
    opacity: 0.7;
}

.card h3 {
    font-size: 1.5rem;
}

.cardDesc {
    position: relative;
    height: 30%;
    font-size: 1rem;
    opacity: 0.7;
}

.cardDesc::before {
    position: absolute;
    content: '';
    bottom: -10%;
    margin-block: -0.5rem;
    left: 0;
    width: 90%;
    height: 1px;
    background-color: var(--accent-color);
}

.card ul {
    padding-top: 1rem;
    padding-left: 1rem;
    list-style: '✓ ';
}

@media screen and (min-width: 1300px) {
    #cards {
        margin-left: 7rem;
        gap: 6rem;
    }
}

/* Aanbod mobile */

@media screen and (max-width: 950px) {
    #shape {
        display: none;
    }

    #aanbod {
        flex-direction: column;
        align-items: center;
        height: auto;
        gap: 5rem;
        margin-block: 5rem;
        padding: 0;
    }

    #aText {
        width: 80%;
        padding: 1rem;
        margin: 0 auto;
        text-align: center;
    }

    #aText h2 {
        margin: 2rem;
    }

    #aText p {
        color: var(--text-color);
        margin-top: 0;
    }

    #cards {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
}

/* Info Desktop */

#info h2 {
    text-align: center;
    font-size: 3rem;
    margin-block: 3rem;
}

#infoFlex {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 5rem;
    align-items: center;
}

#uren {
    width: 70%;
    margin: auto;
}

#uren ul {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#uren ul li {
    display: flex;
    justify-content: space-between;
}

#locatie iframe {
    height: 35vh;
    min-height: 300px;
    width: 30vw;
    min-width: 300px;
}

/* Info Mobile */

@media screen and (max-width: 950px) {

    #infoFlex {
        display: block;
    }

    #uren {
        width: 90%;
    }

    #uren ul {
        padding: 0;
    }

    #locatie {
        margin-top: 3rem;
        padding: 1rem;
    }

    #locatie iframe {
        width: 100%;
    }

}

/* Footer Desktop */

#footer {
    margin-top: 5rem;
    height: 40vh;
    background: var(--main-color);
    color: var(--nav-text-color);
    display: grid;
    place-items: center;
}

#footer .items {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#footer .footer-items {
    width: 200px;
    text-align: center;
}

#footer .footer-items a {
    color: inherit;
    text-decoration: none;
}

#footer .footer-items .social-links {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

/* Footer Mobile */

@media screen and (max-width: 950px) {

    #footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #footer .items {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

}